.map .content {
  height: calc(100% - 56px);
}
.contentItem {
  margin-bottom: 16px;
}
.contentItem:nth-child(even) {
  margin-left: calc(100% - 320px * 2);
  background-image: linear-gradient();
}

.point-selected > div:first-child {
  background-image: linear-gradient(to bottom, #246BB300, #246BB366);
}
.point-selected > div:last-child {
  background-color: #246BB3;
}
.point-selected > div:last-child::after {
  border-color: #246BB3;
}