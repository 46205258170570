.nav {
  background: url("../../style/imgs/bg.png") no-repeat;
  background-size: 100% 100%;
}

.title {
  letter-spacing: 7.2px;
}

.cornerButton {
  width: 60%;
  height: 52px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 2px;
  color: #ffffff;
  opacity: 0.6;
  background-color: transparent;
  border: 1px solid rgba(255,255,255,.8);
  background: linear-gradient(to bottom, rgba(255,255,255,.2), rgba(255,255,255,.1), rgba(255,255,255,.2));
  position: relative;
}
.cornerButton:hover {
  opacity: 1;
}
.cornerButton::before, .cornerButton::after,
.cornerButton>.bottomAngle::before, .cornerButton>.bottomAngle::after {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 3px;
  position: absolute;
}
.cornerButton::before {
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  top: -2px;
  left: -2px;
}
.cornerButton::after {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  top: -2px;
  right: -2px;
}
.cornerButton > .bottomAngle::before {
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  bottom: -2px;
  left: -2px;
}
.cornerButton > .bottomAngle::after {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  bottom: -2px;
  right: -2px;
}

.item {
  height: 100%;
  width: calc(100% / 6);
  max-height: 450px;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: all 0.5s;
}
.item:hover {
  transform: scale(1.03) !important;
}
.item > .name {
  width: 100%;
  text-align: center;
  white-space: nowrap;
  font-family: PingFangSC-Medium;
  font-size: 36px;
  color: #FFFFFF;
  letter-spacing: 4.5px;
  font-weight: 500;
}
.item img {
  width: 60%;
}
.item > .btn:hover {
  opacity: 1;
}
.item::before {
  content: '';
  width: 103%;
  height: 103%;
  display: block;
  border-radius: 4px;
  position: absolute;
  top: -1.7%;
  left: -1.8%;
}
.item:nth-child(5n+1) {
  transform: perspective(10em) rotateY(3deg);
  background-color: rgba(176, 108, 228, 0.8);
}
.item:nth-child(5n+1)::before {
  border: 1px solid rgba(176, 108, 228, 0.8);
  box-shadow: inset 0px 0px 16px 0px rgba(176, 108, 228, 0.5);
}
.item:nth-child(5n+2) {
  transform: perspective(10em) rotateY(3deg) scale(0.9);
  background-color: rgba(0, 153, 184, 0.8);
}
.item:nth-child(5n+2)::before {
  border: 1px solid rgba(0, 153, 184, 0.8);
  box-shadow: inset 0px 0px 16px 0px rgba(0, 153, 184, 0.5)
}
.item:nth-child(5n+3) {
  transform: scaleY(0.85);
  background-color: rgba(36, 146, 255, 0.8);
}
.item:nth-child(5n+3)::before {
    border: 1px solid rgba(36, 146, 255, 0.8);
    box-shadow: inset 0px 0px 16px 0px rgba(36, 146, 255, 0.5);
  }
.item:nth-child(5n+4) {
  transform: perspective(10em) rotateY(-3deg) scale(0.9);
  background-color: rgba(0, 173, 127, 0.8);
}
.item:nth-child(5n+4)::before {
    border: 1px solid rgba(0, 173, 127, 0.8);
    box-shadow: inset 0px 0px 16px 0px rgba(0, 173, 127, 0.5);
  }
.item:nth-child(5n+5) {
  transform: perspective(10em) rotateY(-3deg);
  background-color: rgba(105, 105, 255, 0.8);
}
.item:nth-child(5n+5)::before {
  border: 1px solid rgba(105, 105, 255, 0.8);
  box-shadow: inset 0px 0px 16px 0px rgba(105, 105, 255, 0.5);
}